/* eslint-disable no-restricted-globals */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './home.scss';


const Notfound: React.FunctionComponent<RouteComponentProps> = (props) => {

  return (
    <div className="home">
        <h1>No Session Found!</h1>
    </div>
    
  );
};
export default Notfound;
