import { getExploreName } from '../utils/platform';

export const devConfig = {
  sdkKey: 'vog3LYbjGeGg1TlrbxT07u80G73QrQ3isFEg',
  sdkSecret: 'Dew9fxqgzws2zs3Fu6J641RpVX8TUUF68XUV',
  webEndpoint: 'zoom.us',
  // topic: 'Zoom Video SDK',
  // name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  password: '',
  signature: '',
  sessionKey: '',
  userIdentity: '',
  // role = 1 to join as host, 0 to join as attendee. The first user must join as host to start the session
  // role: 1
};
